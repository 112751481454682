import Vue from 'vue'
import VueRouter from 'vue-router'
import appBar from './../components/app-bar/default-app-bar.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'landing',
    components: {
      default: () => import('../views/Landing.vue'),
      appBar: null
    },
    meta: {
      customName: 'Landing',
      ShowMenu: false
    }
  },
  {
    path: '/me',
    name: 'me',
    components: {
      default: () => import('../views/Me.vue'),
      appBar: appBar
    },
    meta: {
      customName: 'Perfil',
      ShowMenu: true
    }
  },
  {
    path: '/users',
    name: 'users',
    components: {
      default: () => import('../views/Users.vue'),
      appBar: appBar
    },
    meta: {
      customName: 'Usuários',
      ShowMenu: true
    }
  },
  {
    path: '/main',
    name: 'home',
    components: {
      default: () => import('../views/Home.vue'),
      appBar: appBar
    },
    meta: {
      customName: 'Início',
      ShowMenu: true
    }
  },
  {
    path: '/auth',
    name: 'auth',
    components: {
      default: () => import('../views/Auth.vue')
    },
    meta: {
      ShowMenu: false
    }
  }
]
const router = new VueRouter({
  mode: process.env.IS_ELECTRON ? 'hash' : 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  if (!Vue.prototype.$lConfig.DEBUG) {
    console.log('[ROUTER]: triggered beforeEach')
    if (to.name === 'auth' || to.name === 'landing') {
      console.log('[ROUTER]: to -> auth ... so just go')
      next()
      return
    }
    if (
      (to.name !== 'auth' || to.name !== 'landing') &&
      !Vue.prototype.$API.IsLoggedIn()
    ) {
      console.log(
        `[ROUTER]: to: ${to.name} ... but there is no AccessToken. Go back to auth`
      )
      next('auth')
      return
    }
    next()
    if (Vue.prototype.$lConfig.ForceRefresh) {
      Vue.prototype.$lConfig.ForceRefresh = null
      router.go()
    }
  } else {
    next()
    return
  }
})

export default router
